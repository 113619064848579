import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@passthrough/uikit';

const useStyles = makeStyles(() => ({
  blurForPrivacy: {
    backdropFilter: 'blur(8px)',
  },
}));

export const SessionTimeoutDialog = ({ open, onClose, userId }) => {
  const classes = useStyles();
  const history = useHistory();

  const location = useLocation();
  const urlToReturnTo = location.pathname;

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Your session has timed out"
      primaryButtonText="Sign in"
      onSubmit={() => history.push(`/signin/${userId}/?next=${urlToReturnTo}`)}
      className={classes.blurForPrivacy}
    >
      <Typography>
        For your security, your session has timed out due to inactivity. You may
        close this message to copy any text you have entered.
      </Typography>
      <Typography>To continue, sign in again.</Typography>
    </Modal>
  );
};
