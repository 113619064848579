import React from 'react';
import { SessionTimeoutDialog } from 'components/session_timeout_dialog';

const SessionContext = React.createContext();

export const SessionProvider = ({ userId, children }) => {
  const [open, setOpen] = React.useState(false);
  const [dialogEnabled, setDialogEnabled] = React.useState(true);

  const showTimeoutDialog = () => {
    if (dialogEnabled) {
      setOpen(true);
    }
  };
  const hideTimeoutDialog = () => setOpen(false);

  // This should only be used when transitioning from logged in to logged out.
  // It's not possible to transition from logged out to logged in without
  // a full page refresh.
  const disableTimeoutDialog = () => setDialogEnabled(false);

  return (
    <SessionContext.Provider
      value={{ showTimeoutDialog, disableTimeoutDialog }}
    >
      <SessionTimeoutDialog
        open={open}
        onClose={hideTimeoutDialog}
        userId={userId}
      />
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => React.useContext(SessionContext);
